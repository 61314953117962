<template>
  <div
    role="tablist"
    class="collapse-icon"
    :class="collapseClasses"
  >
    <slot />
  </div>
</template>

<script>
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { ref, computed } from '@vue/composition-api'

// Collapse Type
const collapseVariants = {
  default: 'collapse-default',
  border: 'collapse-border',
  shadow: 'collapse-shadow',
  margin: 'collapse-margin',
}

export default Vue.extend({
  props: {
    accordion: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  setup({ type }) {
    const collapseID = ref(uuidv4())
    const collapseClasses = computed(() => {
      const classes = []

      classes.push(collapseVariants[type])

      return classes
    })

    return {
      collapseID,
      collapseClasses,
    }
  },
})
</script>
