


































import Vue, {} from 'vue'
import { ref, getCurrentInstance, computed } from '@vue/composition-api'
import {
  BCard, BCardHeader, BCardBody, BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default Vue.extend({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const parent: {
      hover: boolean;
      accordion: boolean;
      collapseID: string;
      } = getCurrentInstance()!.parent!.proxy as any

    const visible = ref(props.isVisible)
    const collapseItemID = ref(uuidv4())

    const openOnHover = computed(() => (parent.hover !== undefined ? !!parent.hover : false))

    const accordion = computed(() => {
      // eslint-disable-next-line no-shadow
      const { accordion, collapseID } = parent

      return accordion ? `accordion-${collapseID}` : null
    })

    const updateVisible = (val = true) => {
      visible.value = val

      emit('visible', val)
    }

    const collapseOpen = () => {
      if (openOnHover.value) {
        updateVisible(true)
      }
    }

    const collapseClose = () => {
      if (openOnHover.value) {
        updateVisible(false)
      }
    }

    return {
      visible,
      accordion,
      openOnHover,
      collapseItemID,
      collapseOpen,
      collapseClose,
      updateVisible,
    }
  },
})
